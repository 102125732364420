import React from 'react';
import {
  Box, Flex, Heading, Text, Button, VStack, HStack, Image,
  useColorMode, useColorModeValue, Tabs, TabList, TabPanels, Tab, TabPanel
} from "@chakra-ui/react";
import { FaSun, FaMoon } from 'react-icons/fa';

function App() {
  const { colorMode, toggleColorMode } = useColorMode();
  const bg = useColorModeValue('gray.100', 'gray.900');
  const headerbg = useColorModeValue('gray.400', 'gray.800');
  const color = useColorModeValue('black', 'white');
  const footerbg = useColorModeValue('gray.300', 'gray.700');

  return (
    <Box>
      <Box bg={bg} color={color} minH="100vh" p="10px">
        {/* Header */}
        <Flex bg={headerbg} justifyContent="space-between" alignItems="center" p={4} borderRadius="md">
          <Heading>My Portfolio</Heading>
          <Button onClick={toggleColorMode}>
            {colorMode === 'light' ? <FaMoon /> : <FaSun />}
          </Button>
        </Flex>

        {/* Content */}
        <Box display="flex" justifyContent="center" alignItems="center" mt={6}>
          <Tabs isFitted variant="enclosed" width="80%">
            <TabList mb="1em">
              <Tab>About Me</Tab>
              <Tab>Projects</Tab>
            </TabList>

            <TabPanels>
              {/* About Me Section */}
              <TabPanel>
                <Heading size="md" mb={4}>About Me</Heading>
                <Text fontSize="lg">
                  Hi, I'm Konsta, a passionate developer with a love for Backend
                  and exploring new technologies. I mostly enjoy doing backend so i dont need to touch ui or anything like that. i can write clean backend code with js and express.
                </Text>
              </TabPanel>

              {/* Projects Section */}
              <TabPanel>
                <Heading size="md" mb={4}>Projects</Heading>
                <VStack spacing={6} align="stretch">

                  <Box p={4} borderWidth="1px" borderRadius="md">
                    <Heading size="sm" mb={2}>Project: Live notepad</Heading>
                    <Text>
                      I have made Notepad kind app that with right credentials you can log to and keep your notes there and acces them with any device and also edit Live with multiple devices 
                    </Text>
                  </Box>

                  {/* Add more projects here */}
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>

      {/* Footer */}
      <Box as="footer" width="100%" bg={footerbg} p={4} textAlign="center">
        <Text>&copy; 2024 Tyhjyys.com. All rights reserved.</Text>
        <Text as="a" href="mailto:tyhjyys@tyhjyys.com">All inquiries to Tyhjyys@Tyhjyys.com</Text>
      </Box>
    </Box>
  );
}

export default App;
